'use strict';

try {
    let ensureGlobal;

    if (typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope) {
        ensureGlobal = self;
    } else {
        ensureGlobal = window;
    }

    ensureGlobal.Statistic = function (Statistic) {
        Statistic.Type = {
            NOT_SUPPORTED: -1,
            LINE_CHART: 0,
            BAR_CHART: 2,
            DIGITAL: 1
        };
        Statistic.DataRange = {
            MINUTE: 'minute',
            HOUR: 'hour',
            DAY: 'day',
            MONTH: 'month',
            YEAR: 'year'
            /*
             // keep these descriptions because of moment().startOf(range)!!
              moment().startOf('year');    // set to January 1st, 12:00 am this year
             moment().startOf('month');   // set to the first of this month, 12:00 am
             moment().startOf('quarter');  // set to the beginning of the current quarter, 1st day of months, 12:00 am
             moment().startOf('week');    // set to the first day of this week, 12:00 am
             moment().startOf('isoWeek'); // set to the first day of this week according to ISO 8601, 12:00 am
             moment().startOf('day');     // set to 12:00 am today
             moment().startOf('hour');    // set to now, but with 0 mins, 0 secs, and 0 ms
             moment().startOf('minute');  // set to now, but with 0 seconds and 0 milliseconds
             moment().startOf('second');  // same as moment().milliseconds(0);
             */

        }; // NOTE: Keep the Minutes as suffix separated by an underscore

        Statistic.Frequency = {
            NONE: 0,
            EVERY_CHANGE: 1,
            AVERAGE_1: 2,
            AVERAGE_5: 3,
            // don't change!
            AVERAGE_10: 4,
            AVERAGE_30: 5,
            AVERAGE_60: 6,
            EVERY_CHANGE_EXPERT_1: 7, // it's mapped onto the entry 1 (every change max 1/min) by the MS in the LoxAPP3.json
            INTERVAL_5: 8,
            INTERVAL_10: 9,
            INTERVAL_30: 10,
            INTERVAL_60: 11,
            INTERVAL_15: 12
        };
        return Statistic;
    }(ensureGlobal.Statistic || {});
} catch (e) {
    console.error(e);
    debugger;
}
